<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <el-select
            class="type"
            :placeholder="$t('USERS.IS_STAFF')"
            v-model="selectedIsStaff"
            v-if="$currentUser().is_staff"
          >
            <el-option :value="null" :label="$t('USERS.ALL_IS_STAFF')" />
            <el-option :value="true" :label="$t('COMMON.YES')" />
            <el-option :value="false" :label="$t('COMMON.NO')" />
          </el-select>
          <role-selector
            v-if="
              !filterRole && $currentUserCan($permissions.PERM_VIEW_ANY_ROLES)
            "
            @roleChanged="(roleId) => (selectedRole = roleId)"
          />
          <base-input
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            :placeholder="`${$t('COMMON.LOCATIONS')}`"
          >
            <locations-selector
              @locationsChanged="(locations) => (selectedLocations = locations)"
            />
          </base-input>
          <organization-selector
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!--<reseller-selector
            v-if="
              !filterReseller &&
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
            "
            @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
          />-->
      </div>
      <div v-if="users.length > 0" class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="users"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column min-width="140" :label="$t('COMMON.PICTURE')">
              <template v-slot="{ row }">
                <div class="user-image">
                  <div class="user-image-wrapper">
                    <img
                      v-if="row.profile_image"
                      :src="row.profile_image"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="user-image-status">
                    <span>
                      <icon-check :checked="!!row.active" />
                      {{ $t("USERS.ACTIVE") }}
                    </span>
                    <span>
                      <icon-check :checked="!!row.is_staff" />
                      {{ $t("USERS.IS_STAFF") }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="firstname"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span>{{ `${row.firstname} ${row.lastname}` }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.EMAIL')"
              prop="email"
              sortable="custom"
              min-width="220"
            />
            <el-table-column
              v-if="
                !filterRole && $currentUserCan($permissions.PERM_VIEW_ANY_ROLES)
              "
              :label="$t('COMMON.ROLE')"
              prop="roles.name"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span v-if="row.roles[0]">
                  <router-link :to="$objectViewRoute(row.roles[0])">
                    {{ row.roles[0].name }}
                  </router-link>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.LOCATIONS')"
              sortable="custom"
              min-width="220"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            >
              <template v-slot="{ row }">
                <locations :locations="row.allowedLocations" />
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              :label="$t('COMMON.ORGANIZATION')"
              min-width="220"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                !filterReseller &&
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
              "
              :label="$t('COMMON.RESELLER')"
              prop="reseller"
              min-width="220"
            >
              <template v-slot="{ row }">
                <reseller :reseller="row.reseller" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            />
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <!--<el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
                >
                  <a
                    type="text"
                    @click="viewUser(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
                >
                  <a
                    type="text"
                    @click="editUser(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_USERS)"
                >
                  <a
                    type="text"
                    @click="deleteUser(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>-->

                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
                    >
                      <a
                        type="text"
                        @click="viewUser(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
                    >
                      <a
                        type="text"
                        @click="editUser(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_USERS)"
                    >
                      <a
                        type="text"
                        @click="deleteUser(row)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="users.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div v-if="users.length === 0" class="no-data">
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("USERS.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddUser"
          v-if="$currentUserCan($permissions.PERM_CREATE_USERS)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("USERS.ADD_USER") }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
// import ResellerSelector from "@/components/ResellerSelector.vue";
import IconCheck from "@/components/IconCheck.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "user-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    // ResellerSelector,
    IconCheck,
    RoleSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterRole: {
      type: String,
      default: null,
      description: "Role id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      users: [],
      loading: true,
      selectedRole: null,
      selectedOrganization: null,
      selectedReseller: null,
      selectedIsStaff: null,
      selectedLocations: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterRole: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedRole: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedIsStaff: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : { sort: "-created_at" }),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "roles,reseller,organization,allowedLocations",
        };

        if (this.filterRole) {
          params = {
            ...params,
            filter: { ...params.filter, roles: this.filterRole },
          };
        }
        if (this.selectedRole) {
          params = {
            ...params,
            filter: { ...params.filter, roles: this.selectedRole },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterReseller && !this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller && !this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.selectedReseller,
            },
          };
        }
        if (this.selectedIsStaff !== null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              is_staff: this.selectedIsStaff,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("users/list", params);
        this.users = this.$store.getters["users/list"];
        this.total = this.$store.getters["users/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteUser(user) {
      this.$emit("onDeleteUser", user);
    },

    onAddUser() {
      this.$emit("onAddUser");
    },

    viewUser(user) {
      this.$emit("onViewUser", user);
    },

    editUser(user) {
      this.$emit("onEditUser", user);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
